h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-h2;
  //color: $color-title;
  font-weight: normal;
  color: $color-theme;
  line-height: 1.4;
}

h1 {
  font-family: $font-d;
  font-size: 4rem;
  margin-bottom: $spacing-unit * 5/6;
  text-shadow: -2px 2px 2px rgba(0,0,0,0.5);
  @include respond-from(null, $device-xs){
    font-size: 3.5rem !important;
  }
  &.ch-1{
    padding: $spacing-unit * 5/6;
    // background-color: rgba(#000, 0.4);
    font-size: 7rem;
  }
}

h2 {
  position: relative;
  font-family: $font-d;
  font-size: 3.5rem;
  margin-bottom: $spacing-unit * 2/3;
  @include respond-from(null, $device-sm){
    font-size: 3rem;
  }
  &.ch-2{
    margin-bottom: $spacing-unit * 2.5;
    &:after{
      content: '';
      position: absolute;
      width: 100px;
      height: 1px;
      bottom: -35px;
      left: 50%;
      margin-left: -50px;
      background-color: #fff;
    }
  }

  &.secondary-title {
    font-size: 2.6rem;
  }

  @media (max-width: $device-xs) {
    &.home-mobile-title {
      font-size: 2.5rem;
    }
  }
}

h3 {
  font-weight: 600;
  font-size: 2.5rem;
  color: #464950;
  margin-bottom: 1.5rem;
}

h4 {
  font-weight: 400;
  font-size: 2rem;
  font-family: $font-p;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 1.8rem;
  font-weight: 600;
  &.list-head {
    display: list-item;
    list-style: disc;
    margin-left: 20px;
  }
}

.text-underline {
  text-decoration: underline;
}

p {
  color: $color-text;
  font-size: 1.6rem;
  line-height: 1.6;
}

ul{
  &.list-dash{
    list-style: none;
    margin-left: 15px;
    > li{
      position: relative;
      &:before{
        content: '-';
        position: relative;
        left: -15px;
      }
    }
  }
}


a.link-view-film {
  position: fixed;
  bottom: $spacing-unit;
  right: $spacing-unit;
  display: block;
  font-size: 1.1rem;
  color: #FDFDFD;
  padding: 0.8rem $spacing-unit/2;
  text-decoration: none;
  background: rgba(0,0,0,0.6);
  border-radius: 15px;
  z-index: 99;
  letter-spacing: 1px;

  .fa {
    color: #fff;
    margin-right: 5px;
  }

  &:hover, &:focus {
    opacity: 0.8;
  }
}

@media (min-width: 768px) {
  .navbar {
      border-radius: 0;
  }
}

.sec{
  //@extend %container;
  &-vh{
    position: relative;
    height: 100%;
    min-height: 80vh;

    background-size: cover;
    background-position: center bottom;

    &.bg-center {
      background-position: center center;
    }

    display: flex;
    flex-direction: column;
    > div{
      flex: 1;
      z-index: 10;
    }
    &:first-of-type {
      min-height: 100vh;

      @media (max-width: $device-xs) {
        min-height: 70vh;
      }
    }

    @include respond-from($device-md, null){
      &.vh-100{
        min-height: 100vh;
      }
    }

    &:after{
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: .1;
      z-index: 6;
    }


    @include respond-from($device-sm, null){

    }
    @include respond-from(null, $device-sm){
      min-height: 500px;
      //padding: $spacing-unit/2;

      &.vh-100{
        min-height: 500px;
      }
      > .vh-img{
        flex: none;
      }

      .vh-content{
        padding-left: 0;
        padding-right: 0;
        > .e-block-ibox, > .e-block-box{
          padding-left: $spacing-unit;
          padding-right: $spacing-unit;
        }
      }
    }


    &.vh-md {
      min-height: unset;
      @include respond-from($device-md, null){
        min-height: 80vh;

        &.vh-100, &:first-of-type {
          min-height: 100vh;
        }
      }
      @include respond-from(null, $device-sm){
        min-height: unset;
      }
    }

  }
}

.pull-back{
  @extend %pull-back;
}

.vh{ // vertial full height

  &-mc{
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &-bc{
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    padding-bottom: 80px;
    padding-left: 15px;
    padding-right: 15px;
    .wrapper{
      padding-left: 15px;
      padding-right: 15px;
    }
    @include respond-from(null, $device-sm){
      padding-bottom: $spacing-unit;
      .wrapper{
        padding-left: $spacing-unit/2;
        padding-right: $spacing-unit/2;
      }
    }
  }

  &-tc{
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding-top: 160px;
    .e-block-box{
      margin: 0 auto;
    }
    @include respond-from(null, $device-sm){
      padding-top: 120px;
    }
  }
  &-ml{
    align-items: center;
    justify-content: flex-start;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 15px;
    @include respond-from(null, $device-sm){
      padding-bottom: $spacing-unit;
    }
  }
  &-bl{
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 80px;
    padding-left: 15px;
    .wrapper{
      padding-left: 15px;
    }
    .wrappers{
      padding-left: 15px;
      padding-top: 250px;


    }
    a{
      color: #ffffff;
      font-weight: bold;
       
    }

    @include respond-from(null, $device-sm){
      padding-bottom: $spacing-unit;
      .wrapper{
        padding-left: $spacing-unit/2;
        padding-right: $spacing-unit/2;
      }

       .wrappers{
        padding-left: $spacing-unit/2;
        padding-right: $spacing-unit/2;
        padding-top:10px;

      }
    }
  }

  &-tl{
    padding-top: 80px;
    padding-left: 15px;
    .wrapper{
      padding-left: 15px;
    }
    @include respond-from(null, $device-sm){
      padding-top: $spacing-unit;
      .wrapper{
        padding-left: $spacing-unit/2;
        padding-right: $spacing-unit/2;
      }
    }
  }
  &--nopad{
    padding: 0;
    @include respond-from(null, $device-sm){
      padding: 0;
    }
  }
  &-content{
    display: flex;
    height: 100%;
    width: 100%;
  }
  &__fwidth{ // full width
    flex: 1;
  }

  &-img{
    img{
      max-width: 100%;
    }
    @include respond-from($device-sm, null){
      display: none;
    }
  }
  &--s1{
    @include respond-from(null, $device-sm){
      height: auto;
      .vh-content{
        height: auto;
        padding: 60px 15px 60px;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #212121;

      }
    }
  }


  &--push-top {
    .vh-content {
      padding-top: 140px;
      @include respond-from($device-sm, null){
        padding-top: 180px;
      }
    }
  }
}

.p-residences{

  .strips {
    @include respond-from(null, $device-sm){
      min-height: 110vh;
    }

    @media only screen 
      and (min-device-width: 375px) 
      and (max-device-width: 667px) 
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: landscape) { 
        min-height: 200vh;
    } 

    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 568px)
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: landscape) {
        min-height: 240vh;
    }       
  }
  .key-features{

    .info-slider__text-wrap{
      padding: $spacing-unit;
      background-color: #212121;
      align-items: center;
      color: #FDFDFD;
      font-size: 1.6rem;

      .inner-info-notes {
        height: 400px;
        overflow-y: scroll;
        padding-right: 20px;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #999;
          border-radius: 10px;         
        }


        &::-webkit-scrollbar {
          width: 6px;
          background-color: #999;
          border-radius: 10px;         
        }


        &::-webkit-scrollbar-thumb {
          background-color: $color-theme;
          border-radius: 10px;          
        }


      }


    }

    // .list-dash {
    //   font-size: 14px;
    // }
  }

}

.p-services, .p-landscape, .p-amenities, .p-location {
  section.sec {

    .owl-carousel {
      .item {
        &:after{
          content: '';
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: .1;
          z-index: 6;
          transition: all .5s;
        }

        &.active {
          &:after {
            opacity: 0.5;
          }
        }
      }
    }

  }

}

.p-team {

  @include respond-from(null, $device-md){
    .vh--s1 .vh-content {
      display: block;
      // padding: 150px 15px 60px;
    }
  }


  .team-header {
    text-align: left;
    margin-bottom: $spacing-unit;

    @include respond-from($device-sm, null) {
      padding-left: $spacing-unit * 2;
      margin-bottom: 0;
    }

    // @include respond-from($device-md, null) {
    //   padding-left: $spacing-unit * 10;
    // }

    h1 {
      color: #fff;
    }

    img.logo {
      max-width: 150px;
    }

    p {
      font-size: 1.4rem;
      color: #B7A686;
      letter-spacing: 0.18px;
    }
  }

  .team-info {
    text-align: left;
    margin-bottom: $spacing-unit;

    strong {
      font-family: $font-h;
      color: #B7A686;
    }
  }

  .team-navigation{
    list-style: none;
    display: flex;
    margin: 0 0 $spacing-unit 0;
    @include respond-from($device-md, null) {
      margin: 0;
    }

    @extend %pull-back;
    > li{
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-right: 1px solid darken($color-theme, 15);
      padding: 0;
      @include respond-from($device-md, null) {
        padding: 15px;
        border-right: 0;
        position: absolute;
        left: 0;
        top: 20%;
      }

      &:last-of-type{
        border-right: 0;
        @include respond-from($device-md, null) {
          left: auto;
          right: 0;
        }
      }

      > a{
        font-size: 1.8rem;
        line-height: 50px;
        max-width: 600px;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

}

.p-contact, .p-team, .p-press-news, .p-press-news-item, .p-amenities, .p-location, .p-floorplans {
  background: #191919;
}

.p-contact, .p-team, .p-press-news, .p-press-news-item {

  // .vh-tc {
  //   padding-top: 200px;
  //   @include respond-from(null, $device-sm){
  //     padding-top: 180px;
  //   }
  // }

  footer{
    .link-tiles{
      border-top: thin solid #B7A686;
    }
  }
}

.p-press-news {

  .row {
    display: block;
    margin: $spacing-unit 0;
  }

  .e-block-box--s2 {
    margin-left: auto;
    margin-right: auto;
  }

  .e-block-box {
    max-width: 900px;
  }

  a.news-item {
    display: block;
    text-decoration: none;
    text-align: left;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
    opacity: 1;
    height: 600px;

    h2 {
      margin: 0 0 3rem;
      color: #fff;
      font-size: 2rem;
    }

    p {
      font-size: 1.4rem;
    }

    h5 {
      font-size: 1.2rem;
      color: #B7A686;
      letter-spacing: 0.35px;
      margin: $spacing-unit 0;
    }

    .meta-date {
      .view-more {
        margin-left: $spacing-unit;
        opacity: 0;
      }
    }

    &:hover {
      h2 {
        color: #B7A686;
      }

      .meta-date {
        .view-more {
          opacity: 1;
        }
      }
    }
  }
}

.p-press-news-item {

  .row {
    display: block;
    margin: $spacing-unit 0;
  }

  .news-item {
    display: block;
    text-decoration: none;
    text-align: left;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;
    opacity: 1;

    h1 {
      margin: 0 0 $spacing-unit*2;
      color: #fff;
      font-size: 3rem;

      @include respond-from($device-sm, null) {
        font-size: 4rem;
      }
    }

    p {
      font-size: 1.6rem;
    }

    .meta-date {
      font-size: 1.2rem;
      color: #fff;
      letter-spacing: 0.35px;
      margin: 2rem 0;

      a {
        color: #B7A686;
        margin-right: $spacing-unit;
      }
    }

  }
}


.p-contact, .footer-form {

  @include respond-from(null, $device-md){
    // .vh--s1 .vh-content {
    //   display: block;
    //   padding: 150px 15px 60px;
    // }

    .e-block-box--s2 {
      max-width: 600px;
    }
  }

  p {
    font-size: 1.2rem;
    color: #B7A686;
    letter-spacing: 0.35px;
  }

  .e-block-box--s2 {
    margin-left: auto;
    margin-right: auto;
  }

  form {
    display: block;
    margin: $spacing-unit 0;

    input.form-control {
      opacity: 0.6;
      font-size: 12px;
      background: #191919;
      color: #B7A686;
      letter-spacing: 0.35px;
      line-height: 45px;
      height: 45px;
      border: 1px solid #B7A686;
      border-radius: 0;
      box-shadow: none;

      &:focus {
        border: 1px solid #B7A686;
        color: #B7A686;
        background: #191919;
        opacity: 1;
      }
    }

    #other_option_input {
      display: none;
    }

    select.form-control {
      @include respond-from($device-md, null){
        opacity: 0.6;
        font-size: 12px;
        background: #191919;
        color: #B7A686;
        letter-spacing: 0.35px;
        line-height: 45px;
        height: 45px;
        border: 1px solid #B7A686;
        border-radius: 0;
        box-shadow: none;

        &:focus {
          border: 1px solid #B7A686;
          color: #B7A686;
          background: #191919;
          opacity: 1;
        }
      }
    }

    .btn {
      font-size: 12px;
      color: #FDFDFD;
      letter-spacing: 2px;
      line-height: 45px;
      padding: 0;
      height: 45px;
      border-radius: 0;
      background: #B7A686;

      &:hover, &:focus {
        color: #B7A686;
        background: #FDFDFD;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #B7A686;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #B7A686;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #B7A686;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #B7A686;
    }
  }

}


.p-gallery {

  section.sec-vh {

    &:after {
      opacity: 0.3;
    }
  }

  .info-slider {
    padding-top: 140px;
    @include respond-from($device-sm, null){
      padding-top: 180px;
    }
  }

  @include respond-from(null, $device-md){

    .info-slider {
      position: relative;
      .info-slider__sidebar {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        z-index: 666;
        margin: 0;
        .info-slider__nav {
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
          padding: 8px 0;

          & > li {
            line-height: 20px;
            color: #95876c;
            text-transform: uppercase;
            border: 1px solid #95876c;
            padding: 6px 15px;

            &.active, &:hover {
              color: #fff;
              background-color: #95876c;
            }

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  @include respond-from($device-md, null){
    .info-slider {
      padding-top: 0;
      position: relative;
      .info-slider__sidebar {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 666;
        margin: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;

        .info-slider__nav {
          background: rgba(0,0,0,0.60);
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
          padding: 8px 0;
          max-width: 1200px;

          & > li {
            margin-bottom: 0;

            a {
              color: white;
              text-decoration: none;
            }            
          }
        }
      }
    }
  }

  section.sec-explore {
    h1 {
      &:after {
        content: '';
        display: block;
        height: 2px;
        max-width: 100px;
        margin: 5rem auto;
        background: #fff;
      }
    }

    a.start-vr {
      text-align: center;
      color: #B7A686;
      text-shadow: 0 1px 1px rgba(0,0,0,0.50);
      text-transform: uppercase;
      font-size: 1.8rem;
      color: #B7A686;
      letter-spacing: -0.08px;
      font-weight: 500;
      display: block;
      margin: 5rem auto;

      img.icon-vr {
        width: 60px;
        display: block;
        margin: 2rem auto;
        span {
          display: block;
        }
      }
    }
  }

}

/* custom ameinites head styles */
.p-amenities .amenities-heading {
  font-size: 6rem;
  color: white;
}

@media (max-width: 580px) {
  .p-amenities .mobile-hidden {
    display: none;
  }
}

.p-amenities, .p-location {
  h4 {
    margin: $spacing-unit 0;
    font-family: $font-h;
    font-weight: 600;
  }
  &.p-location section.map {
    background-color: #212121;

    &.sec-vh {
      min-height: unset;

      @include respond-from($device-lg, null) {
        min-height: 100vh;
      }
    }
    .vh-ml {
      flex-direction: column;

      @include respond-from($device-lg, null) {
        flex-direction: row;
      }

      padding-top: 0;
      padding-bottom: 0;

    }
  }
  .vh-ml {
    padding-bottom: 80px;
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;


    @include respond-from(null, $device-sm) {
      padding-left: 0;
      padding-right: 0;
    }

    .wrapper{
      padding-left: $spacing-unit;
      padding-right: $spacing-unit;
    }
    @include respond-from(null, $device-sm){
      padding-bottom: $spacing-unit;
      .wrapper{
        padding-left: $spacing-unit/2;
        padding-right: $spacing-unit/2;
      }
    }
  }


  ul.points-list {
    margin: $spacing-unit 0 $spacing-unit $spacing-unit/2;
    padding: 0;
    text-align: left;
    color: #B7A686;
    list-style-type: decimal;
    // list-style-position: inside;

    @include respond-from($device-sm, null) {
      column-count: 2;
      column-gap: 35px;
    }

    li {
      margin: 0 0 $spacing-unit/2 0;
      font-size: 1.4rem;
      a {
        color: #B7A686;
        text-transform: uppercase;
        text-decoration: none;

        span {
          margin: 2px 0 0 0;
          display: block;
          color: #ccc;

          .fa {
            margin-right: 5px;
          }

          @include respond-from($device-md, null) {
            font-size: 1rem;
            opacity: 0;
            transition: all 0.3s;
          }
        }

        &:hover {
          span {
            @include respond-from($device-md, null) {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .wrapper {
    @include respond-from($device-md, null) {
      max-width: 40%;
    }

    &.align-center-md {
      @include respond-from($device-md, null) {
        max-width: 60%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.pad {
      padding: $spacing-unit $spacing-unit;
    }

    &.half {
      max-width: 100%;
      @include respond-from($device-lg, null) {
        max-width: 32%;
      }

      h1 {
        font-size: 2rem;

        @include respond-from($device-lg, null) {
          font-size: 2.5rem;
        }
      }
    }
  }

  .map {
    width: 100%;
    overflow: hidden;

    #map-points {
      position: absolute;
      right: 0;
      top: $spacing-unit;
      max-width: 700px;
      z-index: 10;
      margin-right: -50px;
      display: none;

      @include respond-from($device-md, null) {
        display: block;
      }

      @include respond-from($device-lg, null) {
        max-width: 750px;
      }

      .map-point {
        cursor: pointer;
      }
    }

    #map {
      position: absolute;
      right: 0;
      top: $spacing-unit;
      max-width: 700px;
      z-index: 8;
      margin-right: -50px;

      display: none;

      @include respond-from($device-md, null) {
        display: block;
      }

      @include respond-from($device-lg, null) {
        max-width: 750px;
      }

    }

    #location-map{

      display: block;
      width: 100%;
      margin-bottom: $spacing-unit;

      @include respond-from($device-md, null) {
        width: 100%;
        display: block;
        margin-bottom: $spacing-unit;
      }
      @include respond-from($device-lg, null) {
        display: block;
        z-index: 8;
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
        max-width: 65%;
      }
    }

    .image {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        display: none;
        padding: 30px;
        background-color: #000;
        z-index: 11;

        &:after{
          content: '';
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #000;
          opacity: .1;
          z-index: 15;
        }

        hgroup {
            position: absolute;
            left: 30px;
            bottom: 40px;
            padding-right: 30px;
            z-index: 3;

            h1 {
              text-transform: uppercase;
            }

            h1, p {
              color: #fff;
              text-shadow: none;
            }
        }

        @media (min-width: 1200px) {
          hgroup {
              width: 40%;
          }
        }

        .map__close{
          position: absolute;
          top: $spacing-unit;
          right: $spacing-unit;
          width: 30px;
          height: 30px;
          text-align: center;
          color: #fff;
          background-color: $color-theme;
          z-index: 20;
          i{
            line-height: 30px;
          }
          &:hover{
            background-color: #fff;
            color: $color-theme;
          }
        }
    }
  }

}

.p-floorplans {

  .vh--s1 .vh-content {
    @include respond-from(null, $device-sm){
      background: #191919;
    }
  }

  section.sec-plans {

    &:after{
      right: 50%;
      opacity: .6;

      @include respond-from(null, $device-md){
        right: 0;
      }
    }

    .e-block-box {
      max-width: 450px;
    }

    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.3rem 0;
        a {
          color: #fff;
          font-size: 1.6rem;
          position: relative;

          span {

            display: block;

            @include respond-from($device-sm, null){
              position: absolute;
              right: -250px;
              top: 0;
              opacity: 1;
              transition: all 0.2s;
            }

            color: #B7A686;
            text-transform: uppercase;

            font-size: 1.3rem;

            .fa {
              color: #B7A686;
            }
          }

          &:hover {
            color: #B7A686;
            span {
              @include respond-from($device-sm, null){
                //right: -190px;
                //opacity: 1;
              }
            }
          }
        }
      }
    }

  }

  section.sec-floorplans-view {
    text-align: center;
    padding-bottom: $spacing-unit*2;

    &:after {
      display: none;
    }

    .navigation {
      border-top: 1px solid #B7A686;
      padding: $spacing-unit 0;

      ul {
        li {
          padding: 0 5px;
          display: block;
          @include respond-from($device-md, null) {
            display: inline-block;
          }
          &:after {

            @include respond-from($device-sm, null) {
              content: '';
              display: inline-block;
              height: 12px;
              width: 1px;
              background: #fff;
              margin: 0 0 0 10px;
            }
          }

          &:last-child {
            &:after {
              display: none;
            }
          }

          a {
            font-size: 1.8rem;
            color: #fff;
            &.active, &:hover, &:focus {
              color: #B7A686;
            }
          }
        }
      }
    }

    .sidebar {
      text-align: left;

      ul.menu {
        margin: 0;
        padding: 0;

        li {
          padding: 5px 0;
          text-align: center;
          @include respond-from($device-md, null) {
            text-align: left;
          }
          a {
            font-size: 1.8rem;
            color: #fff;
            &:hover, &:focus {
              color: #B7A686;
            }
          }

          &.active {
            a {
              color: #B7A686;
            }
          }
        }
      }
    }

    .map-preview {
      max-width: 300px;
      display: block;
      margin: $spacing-unit auto;

      @include respond-from($device-sm, null) {
        max-width: 600px;
      }

      @include respond-from($device-md, null) {
        max-width: 450px;
      }
      @include respond-from($device-lg, null) {
        margin: 0 auto;
        max-width: 600px;
      }
    }
    .details {
      text-align: left;
      @include respond-from($device-md, null) {
        text-align: right;
        padding-right: $spacing-unit * 2;
      }

      p {
        margin-bottom: $spacing-unit;
        &:last-child {
          margin: 0;
        }

        span {
          float: left;
        }

        a.download-plan {
          display: block;
          color: #B7A686;
          text-decoration: underline;
          text-align: left;
        }
      }

      .label-total {
        display: block;
        width: 100%;
        border-top: #B7A686 1px solid;
        margin: $spacing-unit 0;
        padding: $spacing-unit 0;
      }

      img.map-mini {
        margin: 0 auto;
      }

      .btn {
        font-size: 12px;
        color: #FDFDFD;
        letter-spacing: 2px;
        line-height: 45px;
        padding: 0;
        height: 45px;
        border-radius: 0;
        background: #B7A686;

        &:hover, &:focus {
          color: #B7A686;
          background: #FDFDFD;
        }
      }
    }

  }
}

.e-block{
  &-box{
    max-width: 600px;
    //text-align: center;
  }
  &-box--s2{
    max-width: 800px;
    
  }
  &-box--ss2{
    max-width: 800px;
    margin-top: 200px;
  }
  &-ibox{
    max-width: 560px;
  }
}

.strips {
  min-height: 80vh;
  overflow: hidden;
  background: url(../images/logo-pattern.png) no-repeat top center;
  background-color: #212121;
  background-size: 110%;
  position: relative;

  @include respond-from($device-md, null) {
    min-height: 90vh;
  }

  .strips__strip {
    // will-change: width, height, top, left, z-index, transform;
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 25%;
    border: 1px solid #1e1b16;
    border-width: 0 0 1px;
    z-index: 2;
    overflow: hidden;
    cursor: pointer;
    transition: all .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s;

    &:nth-child(1) { top: 0; }
    &:nth-child(2) { top: 25%; }
    &:nth-child(3) { top: 50%; }
    &:nth-child(4) { top: 75%; border: 0; }

    @include respond-from($device-md, null) {
      width: 25%;
      min-height: 100%;
      border-width: 0 1px 0 0;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        top: 0;
        left: 25%;
      }

      &:nth-child(3) {
        top: 0;
        left: 50%;
      }

      &:nth-child(4) {
        top: 0;
        left: 75%;
      }
    }

    &.feature-module.interior {
      .strip__inner {
        padding: 0;
      }
    }
  }

  .strip__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include respond-from($device-md, null) {
      &:hover .strip__subtitle {
        top: 20px;
        opacity: 1;
      }
    }
  }
  .strip__titles {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: width .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    background .2s cubic-bezier(0.23, 1, 0.32, 1) 0.8s,
    top .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    left .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    transform .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s;

    .strip__title {
      max-width: 290px;
      color: $color-theme;
      font-family: $font-h;
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-transform: uppercase;
      text-align: center;

      &.top_strip__title {
      	margin-bottom: 15px;
      }

      @media (max-width: $device-xs) {
        font-size: 2rem;
        line-height: 2rem;
      }
    }

    .strip__subtitle {
      max-width: 280px;
      position: relative;
      top: 10px;
      color: $color-theme;
      font-family: $font-p;
      font-size: 1.8rem;
      line-height: 2.3rem;
      text-align: center;
      transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);

      @include respond-from($device-md, null) {
        top: 30px;
        opacity: 0;
      }

      @media (max-width: $device-xs) {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }      
    }

    .strip__more {
      margin: 20px 0;
      max-width: 280px;
      position: relative;
      top: 10px;
      color: $color-theme;
      font-family: $font-p;
      font-size: 1.8rem;
      line-height: 2.3rem;
      text-align: center;
      transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }

  .strip__inner {
    //background-color: #000;
    will-change: width, height, top, left, z-index, transform;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: auto;
    //top: 50%;
    //left: 50%;
    width: 100%;
    height: 100%;
    padding: 100px 30px 30px;
    //transform: translate(-50%,-50%);
    color: #fff;
    background-color: #212121;
    opacity: 0;
    transition: height .6s cubic-bezier(0.23, 1, 0.32, 1),
    top .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    left .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    transform .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s,
    opacity .4s cubic-bezier(0.23, 1, 0.32, 1);

    @include respond-from($device-md, null) {
      padding: 90px;
      justify-content: center;
    }

    h2 {
      color: $color-theme;
      margin: 20px 0 10px;

      &:first-child {
        min-height: 33px;
        margin-top: 0;
      }
    }
    .feature-nav{
      display: block;
      position: absolute;
      z-index: 999;
      bottom: 30vh;
      right: 30px;
      color: #fff;
      font-size: 1.8rem;
      line-height: 1.8rem;
      a{
        color: #fff;
        font-size: 1.4rem;
      }
      .pagingInfo{
        padding: 0 5px;
        display: inline-block;
        min-width: 40px;
        text-align: center;
      }
    }
  }

  .strips__strip--hidden {
    opacity: 0;
  }

  .strip__close{
    display: none;
    position: absolute;
    top: $spacing-unit;
    right: $spacing-unit;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #fff;
    background-color: $color-theme;
    z-index: 2;
    i{
      line-height: 30px;
    }
    &:hover{
      background-color: #fff;
      color: $color-theme;
    }
  }

  &--col2{
    .strips__strip {
      min-height: 50%;
      &:nth-child(1) { top: 0; }
      &:nth-child(2) { top: 50%; border: 0; }

      @include respond-from($device-md, null) {
        width: 50%;
        min-height: 100%;
        border-width: 0 1px 0 0;

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          left: 50%;
        }
      }
    }
  }

  .strips__strip--expanded {
    width: 100%;
    min-height: 100%;
    top: 0 !important;
    left: 0 !important;
    z-index: 3;
    cursor: default;
    transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
    // background-color: $dark-grey-bg;

    .strip__titles {
      // top: 0;
      //transform: translate(-50%,0);
      //height: 0%;
      background: #212121 url(../images/logo-pattern.png) no-repeat center bottom;
      background-size: cover;
      overflow: hidden;
      transition: width .6s cubic-bezier(0.23, 1, 0.32, 1),
          //background 0s cubic-bezier(0.23, 1, 0.32, 1) 1.4s,
          top .6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
          left .6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
          transform .6s cubic-bezier(0.23, 1, 0.32, 1) 0.4s;
        }

        .strip__close{
          display: block;
        }

        .strip__inner {
          top: 0;
          //transform: translate(-50%, 0);
          height: 100%;
          opacity: 1;
          z-index: 6;
          transition: height .6s cubic-bezier(0.23, 1, 0.32, 1),
          top .6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
          left .6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
          transform .6s cubic-bezier(0.23, 1, 0.32, 1) 1s,
          opacity .6s cubic-bezier(0.23, 1, 0.32, 1) 1.4s;
          column-count: 2;
          .feature-img, .feature-slide {
            .interior {
              padding: 50px $spacing-unit;

              @include respond-from($device-sm, null) {
                padding: 50px;
              }
              @include respond-from($device-lg, null) {
                padding: 90px;
              }
            }

            .content {
              margin-top: 22px;

              @include respond-from($device-lg, null) {
                margin-top: 62px;
              }
            }

            @include respond-from($device-md, null) {
          //overflow-y: auto;
          .content {
            display: flex;
            justify-content: space-between;
            .content-interior {
              width: 45%;
            }
          }
        }
      }
    }

    @include respond-from($device-md, null) {
      .strip__titles {
        left: 16.5vw;
        // transform: translate(0,0);
        width: 33.33333%;
        height: 100%;



        .strip__subtitle {
          top: 20px;
          opacity: 1;
        }

        .strip__more {
          opacity: 0;
        }
      }

      .strip__inner {
        top: 0;
        left: 33.33333%;
        transform: translate(0,0);
        width: 66.66667%;
        height: 100%;
        transition: opacity .6s cubic-bezier(0.23, 1, 0.32, 1) 1.4s;
      }
    }
  }
}

.info-slider{
  $comp: '.info-slider';
  background-color: #212121;
  //display: flex;
  #{$comp}__sidebar{
    margin: 0 0 $spacing-unit/2;
    padding: $spacing-unit $spacing-unit;

    @media(max-width: 575.98px){
      padding: 30px 15px;
    }
  }
  #{$comp}__text-wrap{
    //padding: $spacing-unit*2 $spacing-unit;
  }
  #{$comp}__text-content{
    list-style: none;
    margin: 0 0 30px;
    h1{
      font-size: 3rem;
    }
    h2{
      font-size: 2rem;
    }
    > li{
      display: none;
      &.active{
        display: block;
      }
    }
  }
  #{$comp}__gal-content{
    height: 100%;
    margin: 0;
    list-style: none;
    position: relative;
    > li{
      display: none;
      &.active{
        display: block;
      }
    }
    .feature-nav{
      display: block;
      position: absolute;
      z-index: 999;
      bottom: 30px;
      right: 30px;
      color: #fff;
      font-size: 1.8rem;
      line-height: 1.8rem;
      a{
        color: #fff;
        font-size: 1.4rem;
      }
      .pagingInfo{
        padding: 0 5px;
        padding: 0 5px;
        display: inline-block;
        min-width: 40px;
        text-align: center;
      }
    }

    .content-text{
      display: block;
      position: absolute;
      z-index: 4;
      bottom: 30px;
      left: 30px;
      right: 30px;
      color: #fff;
      font-size: 1.8rem;
      line-height: 1.8rem;
      z-index: 60;

      h2 {
        color: #fff;
        text-transform: uppercase;
      }

      @include respond-from($device-sm, null){
        right: auto;
      }

      a{
        color: #fff;
        font-size: 1.4rem;
        text-transform: uppercase;
      }

      .detail {
        transition: all .6s cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0;
      }

      &.active {
        .detail {
          opacity: 1;
        }
      }


      .pagingInfo{
        padding: 0 5px;
        padding: 0 5px;
        display: inline-block;
        min-width: 40px;
        text-align: center;
      }
    }
    .owl-carousel, .owl-stage-outer, .owl-stage, .owl-item, .item{
      height: 100%;
      min-height: 400px;
    }
    .item{
      background-size: cover;
      background-position: center;
    }
  }
  #{$comp}__nav{
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;
    justify-content: center;
    text-align: center;
    list-style: none;
    > li{
      position: relative;
      margin: 0 5px 10px;
      padding: 3px 5px;
      cursor: pointer;
      font-size: 1.4rem;
      color: #fff;
      display: inline-block;
      &.active{
        color: $color-theme;
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        right: -5px;
        bottom: 0;
        width: 1px;
        background-color: #FDFDFD;
      }
      &:last-of-type{
        border: 0;
        &:after{
          content: none;
        }
      }
    }
  }

  @include respond-from($device-md, null){
    display: flex;
    height: 100%;
    #{$comp}__sidebar{
      position: relative;
      flex: 0 0 33.33%;
      display: flex;
      padding: $spacing-unit $spacing-unit $spacing-unit*2;
    }
    #{$comp}__nav{
      position: absolute;
      bottom: 15px;
      left: 20px;
      right: 20px;
      margin: 0;
    }
    #{$comp}__text{
      /* position: absolute;
      display: flex;
      align-items: center; */
      top: 50px;
      right: $spacing-unit;
      bottom: 50px;
      left: $spacing-unit;
    }
    #{$comp}__text{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      padding: 0;
      overflow-y: auto;
    }
    #{$comp}__gal{
      flex: auto;
    }
    #{$comp}__gal-content{
      > li{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}


.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

.legal-popup {
  position: relative;
  background: #000;
  color: #444;
  padding: 40px;
  width: auto;
  max-width: 700px;
  margin: 20px auto;
  box-shadow: 0 10px 25px rgba(0,0,0,.5);

  h4 {
    color: #95876c;
    font-family: $font-d;
    font-size: 3rem;
  }

  h5 {
    font-weight: 400;
  }

  ul {
    li {
      color: white;
      font-size: 14px;
      line-height: 1.8;
    }
  }

  p {
    color: #f3f3f3;
    font-size: 15px;
    line-height: 1.8;
  }

  .legal-address {
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }
}

/* common styles */
.arrow-config,
.residence-wrapper {
  position: relative;
}

.note-block {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  height: 180px;
  width: 250px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;  
}

.residence-wrapper > .note-block {
    background: rgb(165, 148, 114);
    height: 110px;
    width: 110px;  
    text-align: center; 
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
  .residence-wrapper > .note-block {
    display: none;
  }
}

.note-block p {
  margin: 0;
  font-size: 13px;
}

.down-arrow {
  position: absolute;
  bottom: 5vh;
  left: 0;
  right: 0;
  text-align: center;

  @media (max-width: 1440px) {
    img {
      max-width: 30px;
    }
  }
}

.navbar-header {
  position: relative;
}

.view-film-wrap {
  position: absolute;
  top: 50%;
  right: -250%;
  transform: translateY(-50%);
  z-index: 3;

  .view-film {
    color: white;
    padding: 6px 5px;
    font-size: 1.4rem;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: all .2s ease-in;

      &:hover {
        color: #b7a686;
        border-bottom: 1px solid #b7a686;
        background-color: transparent;    
      }
  }

  @media (max-width: 1400px) {
    top: initial;
    right: initial;
    top: 100%;
    transform: translateY(none);
    width: 150px;
    left: -10px;

    .view-film {
      font-size: 1.1rem;
    }
  }

  @media (max-width: $device-sm) {
    top: 50%;
    left: 75px;
  }
}

.gallery-section {
  position: relative;

  .gallery-call-info {
    position: fixed;
    top: 160px;
    right: 50px;
    z-index: 12;

    p,
    a {
      color: white;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      line-height: 8px;
    }

    a {
      text-decoration: none;
    }

    @media (max-width: $device-md) {
      display: none;
    }
  }
}

.home-text {
  align-items: flex-end;
  padding-bottom: 12vh;

  &.flex-center {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    .home-button-group {
      align-self: center;
      margin-top: 20px;
    }
  }

  .gold-color-box {
    margin-left: 20px;
    background: #a59473;
    border-radius: 3px;
    box-shadow: 1px 1px 8px 0 rgba(0,0,0,0.7);
    padding: 8px 12px;
    margin-bottom: 20px;

    p {
      font-family: $font-h;
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .shadow-bg {
    margin-right: auto;
    margin-left: 20px;

    // &.home-text-box {
    //   display: none;
    // }

    h2.ch-2 {
      margin-bottom: 60px;
    }

    p {
      text-align: center;
      margin-left: 20px;
      font-size: 14px;

    }
  }

  @media (max-width: 1440px) {
    .gold-color-box {
      p {
        font-size: 12px;
      }
    }

    &.flex-center {
      .home-button-group {
        .to-contact {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
    }

    .home-text-box {
      .home-title {
        font-size: 20px !important;
        margin-bottom: 0 !important;
      }

      p {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }   
}

.service-content,
.interior-content {
  justify-content: space-between;

  .service-fact-sheet,
  .interior-finishes {
    color: white !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10vw;
  }

  .down-arrow {
    bottom: 4vh;
  } 

  @media (max-width: $device-lg) {
    .interior-finishes {
    	margin-left: 15px;
    }  	
  } 

  @media (max-width: $device-md) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    .service-fact-sheet,
    .interior-finishes{
      margin-right: 0;
      padding-left: 15px;
    }

    .interior-finishes {
    	margin-top: 15px;
    	margin-bottom: 15px;
    	margin-left: 0;
    }
  }

  @media (max-width: $device-sm) {
    .down-arrow {
      bottom: 1vh;
    }
  }

}

.service-content {
  .e-block-box--s2 {
    max-width: 700px;
  }
}

.location-content {
  justify-content: space-between;

    .view-location-wrap {
    max-width: 200px;
    display: flex; 
    flex-direction: column;
    margin-right: 100px;
    margin-bottom: 10px;

    a {
      font-size: 14px;
      color: white;
      text-decoration: none;

      &:first-child{
        margin-bottom: 20px
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    .view-location-wrap {
      margin-right: 0;
    }
  }
}

.call-wrap {
  position: fixed;
  top: 30vh;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: rgba(165, 148, 115, 1);
  border-radius: 8px 0 0 8px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.7);
  overflow: hidden;

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 20px;
    position: relative;

    .fa {
      font-size: 30px;
      color: white;
    }
  }

  .phone-wrap {
    padding: 7px 10px;

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #fdfdfd !important;
      text-transform: uppercase;
      line-height: 1.2;

      &:last-of-type {
        font-size: 20px;
      }
    }

    @media (max-width: $device-sm) {
      display: none;
    }    
  }

  &:visited,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;

    &::after {
      width: 100%;
      right: 75%;
    }
  }

  @media (max-width: $device-xs) {
    .icon-wrap {
      .fa {
        font-size: 20px;
      }
    }

    .phone-wrap {
      p {
        font-size: 10px;

        &:last-of-type {
          font-size: 14px;
        }
      }
    }    
  }
  
}


.col-news-item {
  max-height: 600px;
}

.home-title {
  line-height: 50px;
  text-align: center;
  max-width: 700px;
  color: white !important;
  font-size: 3.5rem !important;
 

  @media (max-width: 700px) {
    font-size: 3rem !important;
    max-width: 500px;
  }

  @media (max-width: $device-xs) {
    font-size: 2rem !important;
    max-width: 400px;
    line-height: 50px;
    text-align: center;
  }

  @media (max-width: 320px) {
    font-size: 3rem !important;
  }
}

@media (max-width: $device-sm) {
  .home-bg {
    min-height: 40vh !important;
  }
}

@media (max-width: $device-xs) {
  .home-bg {
    background-position: 64% center !important;
  }
}

#newsletter-success {
  margin-bottom: 30px;
}

.newsletter-form {
  margin: 0 !important;

  .row {
    margin: 0 !important;
  }

  input,
  select {
    background: rgba(255, 255, 255, 0.8) !important;
  }

  input, button, select {
    height: 35px !important;
    line-height: 35px !important;
  }

  #newsletter-btn {
    letter-spacing: 0;
    margin-top: 5px;
  }
}

.fancybox-opened .fancybox-skin {
  box-shadow: none;
}

.fancybox-skin {
  background: transparent;
}

.bestweb-logo {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 100;

  img {
    width: 80px;
  }

  @media (max-width: $device-xs) {
    display: none;
  }
}

.recaptcha-container {
  max-width: 300px;
}

#subscribe-recaptcha {
    transform-origin: left top;
    -webkit-transform-origin: left top;
}

.grecaptcha-badge {
  bottom: 90px !important;
}

// .shadow-paragraph {
//   position: relative;

//   &::after {
//     content: "";
//     position: absolute;
//     top: -20px;
//     left: 0;
//     bottom: -20px;
//     right: 0;
//     background: rgba(0, 0, 0, 0.22);
//     filter: blur(50px);
//     z-index: -1;
//   }
// }

.shadow-bg {
  padding: 15px 10px;
  border-radius: 5px;

  &.dark {
    background: rgba(0, 0, 0, 0.6);
  }

  &.light {
    background: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    &.dark,
    &.light {
      background: transparent;
    }
  }
}

.desktop-hidden {
  display: none;

  @media (max-width: 768px) {
    display: block;
    min-height: auto !important;
  }
}

.intro-video-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
  }

}

.intro-video-skip {
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #000;
    padding: 10px 20px;
    text-decoration: none !important;
    transition: all .2s ease-in-out;
    color: #fff !important;

    &:hover {
      border-color: transparent;
      color: #fff !important;
      background: rgba(0, 0, 0, 0.3);
    }
}

.home-button-group {
  // display: none;
  .to-contact {
    display: block;
    color: #fff;
    background: #a59473;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 20px;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,.3);
    transition: all .3s ease-in-out;

    &:hover {
      box-shadow: none;
    }
  }
}

.home-onload-popup {
  .inner-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    max-width: 550px;
    width: 100%;

    @media (max-width: 480px) {
      min-width: 300px;
    }
  }
}

//for years filter in construction update
.year-filter-container{
  background-color: #1E1E1E;
  color: #fff !important;
  padding-top: 5rem;

  .img-container{
    display: flex;
    justify-content: center;
    width: 100%;

    img{
      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        max-height: 350px;
      }

      @media (max-width: 575.98px) {
        max-height: 200px;
      }

    }
  }

  .year-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
    font-size: 14px;

    li{
      //border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      padding: 0px 20px;
    }

    li:first-child{
      border-left: 1px solid #dddddd;
    }

    li.active{
      a{
        border-bottom: 2px solid #B6A686 !important;
      }
    }
  }

  .month-menu{
    display: flex;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-content: stretch;
    margin-bottom: 3rem;
    font-size: 13px;

    li:first-child{
      border-left: 1px solid #dddddd;
    }
    li{
      border-right: 1px solid #dddddd;
       border-right: 1px solid #dddddd;
        padding: 0px 20px;

    }

    li.active{
      color: #B6A686;
      a{
        border-bottom: 2px solid #B6A686 !important;
      }
    }
  }

  .nav-tabs {
    border-bottom: 0px;
  }

  .nav-tabs>li>a {
    border: 0px !important;
    color: #fff;
    padding: 2px 3px;
  }

  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #B6A686;
    cursor: default;
    background-color: transparent;
    border-bottom: 2px solid #B6A686;
  }

  .nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: transparent;
    //border: 0px !important;
  }

  .carousel-container{

    display: flex;
    justify-content: center;
    align-items: center;

    .ctrl{
      display: inline-block;
      min-width: 22px;
      cursor: pointer;
      i{
        color: #B6A686;
      }
    }

    .owl-carousel {
      width: 90%;

      .owl-item{
        img{
          cursor: pointer;
        }
      }
    }

  }
//owl-item active

  //.owl-carousel .owl-nav .owl-prev {
  //  position: absolute;
  //  display: inline-block;
  //  left: 0;
  //  top: 50%;
  //  transform: translateY(-50%);
  //}
  //
  //.owl-carousel .owl-nav .owl-next {
  //  position: absolute;
  //  display: inline-block;
  //  right: 0;
  //  top: 50%;
  //  transform: translateY(-50%);
  //}

}


.product-slider { padding: 45px; }

.product-slider #carousel { border: 4px solid #1089c0; margin: 0; width:100%; text-align: center; height: auto; }

.product-slider #thumbcarousel { margin: 12px 0 0; padding: 0 45px;  }

.product-slider #thumbcarousel .item { text-align: center; }

.product-slider #thumbcarousel .item .thumb { border: 4px solid #cecece; width: 20%; margin: 0 2%; display: inline-block; vertical-align: middle; cursor: pointer; max-width: 98px; }

.product-slider #thumbcarousel .item .thumb:hover { border-color: #1089c0; }

.product-slider .item img { width: 100%; height: auto; }

.carousel-control { color: #0284b8; text-align: center; text-shadow: none; font-size: 30px; width: 30px; height: 30px; line-height: 20px; top: 23%; }

.carousel-control:hover, .carousel-control:focus, .carousel-control:active { color: #333; }

.carousel-caption, .carousel-control .fa { font: normal normal normal 30px/26px FontAwesome; }
.carousel-control { background-color: rgba(0, 0, 0, 0); bottom: auto; font-size: 20px; left: 0; position: absolute; top: 30%; width: auto; }

.carousel-control.right, .carousel-control.left { background-color: rgba(0, 0, 0, 0); background-image: none; }


//advertiesment redirect page css
.p-adver-landing{
  .error{
    color: #a94442;
    font-size: 1.2rem;
    font-weight: normal;
  }
  .banner{
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;

    .row{
      >div{
        @media (max-width: 575.98px) {
          padding: 7px;
        }
      }
    }

    //.col-xs-12{
    //  @media (min-width: 576px) and (max-width: 767.98px) {
    //    width: 50%;
    //  }
    //}

    img{
      max-width: 180px;

      @media (max-width: 575.98px) {
        //max-width: 100px;
        max-width: 100%;
      }
      @media (min-width: 576px) and (max-width: 767.98px) {
        max-width: 140px;
      }

    }

    .url-container{
      font-size: 14px;
      color: #e3be72;
      text-align: right;

      @media (min-width: 576px) {
        padding-top: 25px;
        margin-right: 15px;

      }

      a{
        color: inherit;
        text-decoration: none;
      }
    }

    .title-container{
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 767.98px) {
        top: 20%;
      }

      @media (max-width: 575.98px) {
        top: 25%;
      }

      h1{
        font-size : 61px;
        line-height : 65px;
        color : #FFFFFF;
        margin: 0px;
        text-shadow: none;

        @media (max-width: 575.98px) {
          line-height : 50px;
        }
      }
    }
  }

  .form-container{
    .form-box{
      background-color: #ffffff;
      padding: 3.5rem 7rem;
      margin-bottom: 8rem;

      @media (max-width: 575.98px) {
        padding: 3.5rem 2rem;
      }
      @media(min-width: 768px){
        margin-top: -75px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      }
      @media(min-width: 992px){
        margin-top: -100px;
      }

      h3{
        font-size : 26px;
        line-height : 50.7px;
        color : #A5904D;
        margin-bottom: 0px;

        @media (max-width: 575.98px) {
          line-height: 38.7px;
        }
      }
      .form-dec{
        font-size : 18px;
        line-height : 37px;
        color : #999999;
      }

      form{
        margin-top: 35px;
        .form-group{
          margin-bottom: 25px;
          .form-control{
            border-color: #a5904d;
            height: 40px;
          }
        }
        button{
          height: 40px;
          background-color: #a5904d;
          color: #ffffff;
          font-size: 16px;
          letter-spacing: .5px;
        }
      }
      span{
        font-size : 14px;
        //line-height : 37px;
        color : #999999;
        color : rgb(153, 153, 153);
      }
    }
  }

  .slider-container{
    padding: 0px;

    h2{
      color: #a5904d;
    }

    .slider-box{
      background-color: #a5904d;
      padding: 3rem 7rem;

      @media (max-width: 767.98px) {
        padding: 3rem 3rem;
      }
      
      @media (min-width: 768px) and (max-width: 1199.8px) {
        padding: 3rem 5rem;
      }

      .arcitech-slider{

        .owl-item > div {
          cursor: pointer;
          margin: 6% 8%;
          transition: margin 0.4s ease;
          border: 4px solid #ffffff;
        }
        .owl-item.center > div {
          cursor: auto;
          //margin: 0;
          margin: 2% 0% 0% 0%;
        }
        .owl-item:not(.center) > div:hover {
          opacity: .75;
        }

        .owl-nav{
          display: flex;
          justify-content: space-between;

          @media (min-width: 320px) and (max-width: 439.98px) {
            margin-top: -35px;
          }
          @media (min-width: 440px) and (max-width: 575.98px) {
            margin-top: -50px;
          }
          @media (max-width: 767.98px) {
            //margin-top: 15px;
          }
          @media (min-width: 768px) {
            margin: 0% 3%;
          }

          @media (min-width: 992px) and (max-width: 1199.8px) {
            margin: 0% 4%;
          }

          .owl-prev,.owl-next{
            border: 1px solid #ffffff;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

  }

  .footer-thumb-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .thumbnail-container{
    //background-color: #819aba;

    padding-top: 25px;


    @media (min-width: 575.98px) {
      padding-top: 50px;
    }

    @media (max-width: 767.98px) {
      background: rgb(128,152,185);
      background: linear-gradient(90deg, rgba(128,152,185,1) 0%, rgba(174,187,198,1) 100%);
    }

    .row{
      >div{
        padding: 0px;
      }

      >div.col-xs-6{
        @media (max-width: 575.98px) {
          width: 100%;
        }
      }
    }

    h2{
      text-align: center;
      color: #ffffff;
    }

    .thumbnail{
      border: 0px;
      border-radius: 0px;
      position: relative;

      .img-container{
        min-height: 320px;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .img-overlay{
          position: absolute;
         left: 15px;
          top: 15px;
          right: 15px;

          h4{
            color : #FFFFFF;
            line-height: 1;
            margin-bottom: 3px;
          }
          span{
            font-size : 10px;
            line-height : 1;
            color : #FFFFFF;
            display: block;
          }
        }
      }
      .caption{
        background-color: #ffffff;
        opacity: .8;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px 20px;
        p{
          font-size : 15px;
          color : #808080;
          color : rgb(128, 128, 128);
          margin-bottom: 0px;
          line-height: 1.4;
        }

      }
    }

  }

  .footer-sec{
    min-height: 350px;
    display: flex;
    justify-content: start;
    align-items: flex-end;

    div{
      padding-left: 30px;
      margin-bottom: 30px;
      p{
        font-size : 14px;
        line-height : 24px;
        color : #4D4D4D;
        margin-bottom: 0px;
      }
    }
  }
}


//gallery page menu slider  info-slider__nav
.gallery_carousel{
  li{
    margin: 0 5px;
    padding: 3px 5px;
    cursor: pointer;
    font-size: 1.4rem;
    color: #fff;

    a{
      color: #ffffff;
    }

    &.active, &:hover {
      color: #95876c;
    }

    &:after {
      display: none;
    }

    @media (min-width: 992px){
      margin-bottom: 0;
    }

    @media (min-width: 1000px) and (max-width: 1199.98px) {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .owl-stage-outer{
  z-index:2 !important;
  margin: 0 40px;
  }

  .owl-nav{
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
      z-index:1 !important;

    i{
      font-size: 25px;
    }
  }

  //.owlprev,.owl-next{
  //  width: 15px;
  //  position: absolute;
  //  top: 50%;
  //}

  .owl-prev {
    margin-left: 15px;

    @media (max-width: 575.98px) {
      margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 991.98px){
      margin-left: -15px;    }
  }

  .owl-next {
    margin-right: 15px;

    @media (max-width: 575.98px) {
      margin-right: 0;
    }

    @media (min-width: 768px) and (max-width: 991.98px){
      margin-right: -15px;
    }
  }
  //.owl-prev i, .owl-next i {transform : scale(1,6); color: #ccc;}

}