@charset "utf-8";

@each $font-face in '35188A_1_0', '35188A_3_0' {
  @font-face {
    font-family: $font-face;
    font-style: normal;
    font-weight: normal;
    src: url('../webfonts/#{$font-face}.svg');
    src: url('../webfonts/#{$font-face}.eot?') format('eot'),
    url('../webfonts/#{$font-face}.woff') format('woff'),
    url('../webfonts/#{$font-face}.ttf') format('truetype');
  }
}
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300');

//fonts
$font-p: '35188A_1_0', sans-serif; //'crimsontext-roman', sans-serif;
$font-h: '35188A_1_0', sans-serif; //'Circular-Std-Bold', sans-serif;
$font-d: '35188A_1_0', sans-serif;
$font-i: '35188A_3_0', serif;

$font-p2: '35188A_2_0', sans-serif;
$font-h2: '35188A_2_0', sans-serif;


$spacing-unit: 30px;
$color-text: #FDFDFD;
$color-title: #464951;
$color-light: #DEE7EA;
$color-theme: #B7A686;
// $devices
$device-xs: 480px;
$device-sm: 768px;
$device-md: 992px;
$device-lg: 1200px;

//margin bottom classs
$mb-classes: 0, 10, 15, 20, 15, 20, 25, 30, 40, 50, 60;

@mixin respond-to($min, $max) {
  @if $min==null {
    @media (max-width: $max - 1) {
      @content;
    }
  }
  @else if $max==null {
    @media (min-width: $min) {
      @content;
    }
  }
  @else {
    @media (min-width: $min) and (max-width: $max - 1) {
      @content;
    }
  }
}

@mixin respond-from($min, $max){
  @if $min == null{
    @media (max-width: $max - 1) {
      @content;
    }
    } @else if $max == null{
      @media (min-width: $min) {
        @content;
      }
      } @else{
        @media (min-width: $min) and (max-width: $max - 1) {
          @content;
        }
      }
    }

    @mixin transform($transformation) {
      transform: $transformation;
      -webkit-transform: $transformation;
      -moz-transform: $transformation;
      -ms-transform: $transformation;
      -o-transform: $transformation;  
    }

    @mixin row-table($device) {
      @include respond-to(min,
        $device) {
        display: table !important;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        > div {
          display: table-cell;
          vertical-align: middle;
          float: none;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @mixin text-align($rule, $device) {
      text-align: $rule;
      @include respond-to(max, $device) {
        text-align: center;
      }
    }

    @mixin clearfix() {
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }

    @import 
    "neat",
    "base",
    "layout";
