/* -----------------------
html
----------------------- */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
  @include respond-from($device-md, $device-lg) {
    font-size: 60%;
  }
  @include respond-to($device-sm, $device-md) {
    font-size: 58%;
  }
  @include respond-to(null, $device-sm) {
    font-size: 56%;
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}


/**
 * Basic styling
 */

 body {
  font-family: $font-p2;
  color: $color-text;
  font-size: 2.2rem;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  opacity: 0;
  transition: all .4s ease-in-out;

}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 blockquote,
 pre,
 ul,
 ol,
 dl,
 figure,
 %vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

/* base color */
.white {
  color: white;
}


/**
 * Lists
 */

 ul,
 ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}


/**
 * Headings
 */


/* h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    } */


/**
 * Links
 */

 a {
  color: $color-theme;
  text-decoration: none;
  &:visited {
    color: darken($color-theme, 25%);
  }
  &:hover {
    color: darken($color-theme, 10%);
    text-decoration: underline;
  }
}

%container{
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
}

%pull-back{
  margin-left: -$spacing-unit;
  margin-right: -$spacing-unit;
}


/* mixin comp styles */

.row.no-padding {
  margin: 0;
  >[class*="col-"] {
    padding: 0;
  }
}

.row.col-right {
  > div {
    float: right;
  }
}

%v-middle {
  height: 100%;
  display: table;
  > div {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }
}

.container-fluid{
  > .navbar-collapse{
    position: relative;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    transition: height .1s ease-in;
    height: 0;
    &.in{
      height: auto;
      overflow: auto;
    }
  }

}
.navbar{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  //overflow: hidden;
  transition: all .3s ease-out;
  transition-delay: .25s;
  transition-property: height,background;

  padding: 0 30px;
  background-color: transparent;
  background: linear-gradient(180deg,rgba(0,0,0,.9),transparent);
  border: 0;

  &.navbar-fixed.toggle, &.toggle {
    @include respond-from(null, $device-sm){
      height: 100%;
      background: linear-gradient(180deg,rgba(0,0,0,.7),rgba(0,0,0,.7));
    }
  }

  &.navbar-fixed{
    height: auto;
    .navbar-brand{
      margin-top: 20px;
      padding-top: 80px;
    }
  }
  .navbar-header{
    display: flex;
    align-items: flex-start;
    //padding-top: 30px;
  }
  .navbar-brand{
    position: relative;
    height: auto;
    width: 80px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 155px;
    overflow: hidden;
    transition: padding 0.3s ease-in, margin 0.3s ease-in;
    img{
      max-width: 100%;
    }
    &-wrap{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }


  .navbar-nav{
    > li{
      margin: 0 4px;

      @include respond-from(null, $device-sm){
        text-align: center;
      }

      a {
        color: #fff;
        padding: 6px 5px;
        font-size: 1.3rem;
        border-bottom: 1px solid transparent;
        transition: all 0.2s ease-in;

        @include respond-from(null, $device-sm){
          font-size: 1.6rem;
        }

        &:hover, &:focus {
          color: $color-theme;
          border-bottom: 1px solid $color-theme;
          background-color: transparent;
        }
      }
    }
  }
  @include respond-from($device-sm, null){
    .navbar-right{
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      margin-right: 0;
    }
    .navbar-collapse{
      margin-left: auto;
    }
  }

  @include respond-from($device-lg, null){
    .navbar-collapse{
      flex: 1;
      height: auto;
      padding: 0;
      overflow-y: auto;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  @include respond-from(null, $device-sm){
    display: block;
    &.navbar-fixed{
      .navbar-brand{
        margin-top: 10px;
        padding-top: 55px;
      }
      .navbar-collapse{
        max-height: calc(100vh - 90px);
      }
    }
    .navbar-brand{
      width: 60px;
      padding-top: 90px;
      margin-top: 10px;
    }
    .navbar-nav{
      flex: 1;
      > li{
        > a{
          &:hover{
            border-bottom: 1px solid transparent;
            background-color: rgba(#111, 0.4);
          }
        }
      }
    }
    .navbar-collapse{
      max-height: calc(100vh - 125px);
    }
    .navbar-toggle{
      margin-left: auto;
      position: relative;
      top: 20px;
      width: 25px;
      height: 22px;
      margin-right: 0;
      .icon-bar{
        will-change: width,transform;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 25px;
        height: 2px;
        background: #fff;
        transition: all .3s cubic-bezier(.4,.01,.165,.99);
        + .icon-bar{
          margin-top: 0;
        }
        &:nth-of-type(2){
          transform: translateY(8px) translateX(0) rotate(45deg);
        }
        &:nth-of-type(3){
          opacity: 0;
          top: 8px;
          width: 20px;
        }
        &:nth-of-type(4){
          top: 16px;
          width: 25px;
          transform: translateY(-8px) translateX(0) rotate(-45deg);
        }
      }
      &.collapsed .icon-bar{
        &:nth-of-type(2){
          transform: translateY(0) translateX(0) rotate(0);
        }
        &:nth-of-type(3){
          opacity: 1;
        }
        &:nth-of-type(4){
          width: 15px;
          transform: translateY(0) translateX(0) rotate(0);
        }
      }
    }
  }
}
footer{
  @extend %container;
  //background: linear-gradient(180deg,rgba(0,0,0,0.8),rgba(0,0,0,0.5),transparent);
  background-color: #D7D2CB;
  .link-tiles{
    list-style: none;
    display: flex;
    background-color: #e6e6e4;
    margin-bottom: 0;

    @extend %pull-back;
    > li{
      display: flex;
      flex: 1;
      border-right: 1px solid darken($color-theme, 15);
      align-items: center;
      justify-content: center;
      &:last-of-type{
        border-right: 0;
      }
      > a{
        line-height: 140px;
        max-width: 600px;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
  > .content{
    display: flex;
    h6{
      font-family: $font-d;
      font-size: 2.8rem;
      color: #76787A;
    }
    p{
      font-size: 1.2rem;
      line-height: 2.2rem;
      font-family: $font-d;
      color: #95876C;
      margin-bottom: 0;
    }
    a{
      color: #95876C;
      &:hover{
        color: darken(#95876C, 10);
      }
    }
  }
  .f-container-1, .f-container-2{
    display: flex;
    flex: 1;
    padding: $spacing-unit 0;
    justify-content: flex-end;
  }
  .f-container-1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    .logo-wrapper{
      margin-right: 60px;
      padding-bottom: 5px;

      img {
        max-width: 64px;
      }

      @media (max-width: $device-xs) {
        display: none;
      }
    }
    .info-wrapper{
      align-items: flex-end;
    }
  }
  .f-container-2{
    &.no-flex {
      flex: 0;
    }
    .social-links{
      list-style: none;
      display: inline-block;
      margin: 0;
      padding-top: 6px;
      flex: none;
      margin-right: $spacing-unit;
      padding-right: $spacing-unit;
      border-right: 1px solid $color-theme;
      > li{
        display: inline-block;
        margin: 0 5px;
        font-size: 1.5rem;

        > a {
          font-size: 20px;
        }
      }
    }

    .company-logos {
      margin-right: $spacing-unit;
      padding-right: $spacing-unit;
      border-right: 1px solid $color-theme;
      display: flex;

      .company-logo {
        background-size: cover;
        background-position: center;
        width: 140px;
        height: 65px;
        position: relative;

        &:first-child() {
          margin-right: 10px;
        }
      }
    }
    .copyright{
      text-align: right;
    }
  }
  @include respond-from(null, 1200px){
    > .content{
      display: block;
      padding: $spacing-unit 0;
      h6{
        margin-bottom: $spacing-unit * 1/5;
      }
    }
    .f-container-1, .f-container-2{
      display: block;
      padding: 0;
    }
    .f-container-1{
      text-align: center;
      margin-bottom: $spacing-unit/2;
      .logo-wrapper{
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .f-container-2{
      .social-links{
        display: block;
        text-align: center;
        padding: 0;
        border: 0;
        margin: 0 0 $spacing-unit/2;
      }

      .company-logos {
        justify-content: center;
        margin: 20px 0;
        padding: 0;
        border: none;
      }

      .copyright{
        text-align: center;
      }
    }
  }
}

.pictured-note {
  text-align: right;
  font-size: 12px;
  margin-right: 10px;

  @media (max-width: 768px) {
    display: none;
  }
}